import React from "react";
import { FaPizzaSlice, FaHamburger, FaMortarPestle } from "react-icons/fa";
import { GiNoodles, GiChopsticks } from "react-icons/gi";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Category = () => {
  return (
    <List>
      <SLink to={"/cuisine/korean"}>
        <FaMortarPestle />
        <h4>Korean</h4>
      </SLink>
      <SLink to={"/cuisine/italian"}>
        <FaPizzaSlice />
        <h4>Italian</h4>
      </SLink>
      <SLink to={"/cuisine/american"}>
        <FaHamburger />
        <h4>American</h4>
      </SLink>
      <SLink to={"/cuisine/japanese"}>
        <GiNoodles />
        <h4>Japanese</h4>
      </SLink>
      <SLink to={"/cuisine/french"}>
        <GiNoodles />
        <h4>French</h4>
      </SLink>
      <SLink to={"/cuisine/chinese"}>
        <GiChopsticks />
        <h4>Chinese</h4>
      </SLink>
    </List>
  );
};

const List = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0rem;
`;

const SLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 0.5rem;
  text-decoration: none;
  background: linear-gradient(35deg, #7f22b9, #2511df);
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  transform: scale(0.9);

  @media (max-width: 768px) {
    margin: 0 0.2rem;
    width: 3rem;
    height: 3rem;
    transform: scale(0.8);
  }

  @media (max-width: 480px) {
    margin: 0 0.05rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  h4 {
    color: white;
    font-size: 0.6rem;

    @media (max-width: 768px) {
      font-size: 0.5rem;
    }

    @media (max-width: 480px) {
      font-size: 0.4rem;

    }
  }
  svg {
    color: white;
    font-size: 1.2rem;

    @media (max-width: 768px) {
      font-size: 1rem;
    }

    @media (max-width: 480px) {
      font-size: 0.8rem;

    }
  }
  &.active {
    background: linear-gradient(to right, #700d6b, #cd17be);

    svg {
      color: white;
    }
    h4 {
      color: white;
    }
  }
`;

export default Category;
