import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Recipe = () => {
  let params = useParams();
  const [details, setDetails] = useState({});
  const [activeTab, setActiveTab] = useState("instructions");

  const fetchDetails = async () => {
    const data = await fetch(
      `https://api.spoonacular.com/recipes/${params.name}/information?apiKey=${process.env.REACT_APP_API_KEY}`
    );
    const detailsData = await data.json();
    setDetails(detailsData);
  };

  useEffect(() => {
    fetchDetails();
  }, [params.name]);
  return (
    <DetailWrapper>
      <divs>
        <h2>{details.title}</h2>
        <img src={details.image} alt="" />
      </divs>
      <Info>
        <Button
          className={activeTab === "instructions" ? "active" : ""}
          onClick={() => setActiveTab("instructions")}
        >
          Instructions
        </Button>
        <Button
          className={activeTab === "ingredients" ? "active" : ""}
          onClick={() => setActiveTab("ingredients")}
        >
          Ingredients
        </Button>
        {activeTab === "instructions" && (
          <div>
            <i>
              <h3 dangerouslySetInnerHTML={{ __html: details.summary }}></h3>
            </i>

            <h3 dangerouslySetInnerHTML={{ __html: details.instructions }}></h3>
          </div>
        )}
        {activeTab === "ingredients" && (
          <ul>
            {details.extendedIngredients.map((ingredient) => (
              <li key={ingredient.id}>{ingredient.original}</li>
            ))}
          </ul>
        )}
      </Info>
    </DetailWrapper>
  );
};

const DetailWrapper = styled.div`
  margin-top: 4rem;
  margin-bottom: 2rem;
  display: flex;

  @media (max-width: 860px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex-direction: column;
  }

  @media (max-width: 480px) {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  divs {
    text-align: center;
    margin-bottom: 2rem;
  }

  .active {
    background: linear-gradient(35deg, #7f22b9, #2511df);
    color: white;
  }
  h2 {
    margin-bottom: 1rem;
    min-width: 30rem;

    @media (max-width: 768px) {
      min-width: 20rem;
    }

    @media (max-width: 480px) {
      min-width: 10rem;
    }
  }
  img {
    width: 100%;

    @media (max-width: 768px) {
      width: 80%;
    }

    @media (max-width: 480px) {
    }
  }
  li {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  ul {
    margin-top: 1.5rem;
  }
`;
const Button = styled.button`
  padding: 1rem 1rem;
  color: #2511df;
  background: white;
  border: 1.5px solid black;
  margin-right: 1rem;
  font-weight: 600;
`;
const Info = styled.div`
  margin-left: 2rem;

  h3 {
    font-size: 1rem;
    color: rgb(56, 56, 56);
    line-height: 2rem;
    margin: 1rem 0rem;
  }
`;

export default Recipe;
